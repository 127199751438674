/* eslint-disable */
jQuery(document).ready(function ($) {
  let block = $('.wp-block-app-articles-filter');

  // in page single "article/{nome} are two blocks with the same class get the second one if desktop > 992 and the first one if mobile < 992

  if (block.length > 1) {
    if (window.innerWidth > 992) {
      block = $(block[1]);
    } else {
      block = $(block[0]);
    }
  }

  if (block.length) {
    const form = block.find('form.block-content_form');
    const textInput = block.find('.block-content_form_fields_field input[name="texto"]');
    const url = form.attr('action');

    const openFilterMenuButton = block.find('.toggle-menu-button');
    const closeFilterMenuButton = block.find('.block-content_close-button');

    function toggleFilterMenu() {
      const filterMenu = block.find('.block-content');
      filterMenu.toggleClass('open');
      $('body').toggleClass('hidden-overflow');
    }

    openFilterMenuButton.on('click', toggleFilterMenu);
    closeFilterMenuButton.on('click', toggleFilterMenu);

    const openAdvancedFiltersButton = block.find('.block-content_form_fields_field_buttons_filter');
    const radioButtons = block.find('.block-content_form_options input[type="radio"]');
    const volumeField = block.find('select[name="volume"]');

    function toggleAdvancedFilters() {
      const advancedFilters = block.find('.block-content_form_fields_advanced-filters');
      advancedFilters.toggleClass('show');
    }

    function manageAdvancedFiltersByOption(e) {
      const advancedFilters = block.find('.block-content_form_fields_advanced-filters');
      if (e.target.value !== 'google-scholar') {
        advancedFilters.addClass('show');
        openAdvancedFiltersButton.addClass('show');
        return;
      }

      advancedFilters.removeClass('show');
      openAdvancedFiltersButton.removeClass('show');
    }

    $('.custom-select div[name="issue"]').on('click', function () {
      var customSelect = $(this).closest('.custom-select');
      customSelect.toggleClass('is-open');

      if (customSelect.hasClass('is-open')) {
        customSelect.find('.select-items').removeClass('select-hide');
      } else {
        customSelect.find('.select-items').addClass('select-hide');
      }
    });

    $('.custom-select div[name="issue"]').css({
      'pointer-events': 'none',
      'opacity': '0.5'
    });

    block.on('click', '[name="volume"]', function (e) {
      const value = e.target.getAttribute('value');

      if (value !== null) {
        const parts = value.split('v');
        if (parts.length === 2) {
          const year = parts[0];
          const volume = parts[1];
          const issueField = block.find('[name="issue"]');

          if (!year || !volume) {
            issueField.html('<div value="">ISSUE</div>');
            issueField.addClass('disabled');
            return;
          }

          const data = {
            action: 'get_issue_value',
            year,
            vol: volume
          };

          $.post(globalVars.ajax_url, data, function (response) {
            console.log('AJAX Response:', response);

            if (!response) {
              return;
            }

            $('.custom-select div[name="issue"]').css({
              'pointer-events': 'visible',
              'opacity': '1'
            });
            issueField.html(response);

            $('.custom-select div[name="issue"] .select-items div[value]').on('click', function () {
              var customSelect = $(this).closest('.custom-select');
              var selectedText = $(this).text();
              var selectedValue = $(this).attr('value'); // Adicione esta linha para obter o valor selecionado

              customSelect.find('.select-selected').text(selectedText).attr('value', selectedValue); // Atualize o texto e o atributo value
            });
          });
        }
      }
    });

    openAdvancedFiltersButton.on('click', toggleAdvancedFilters);

    radioButtons.on('change', manageAdvancedFiltersByOption);

    function manageFormSubmit(e) {
      // Evite o envio do formulário
      e.preventDefault();

      const searchin = block.find('input[name="searchin"]:checked').val();
      const texto = block.find('input[name="texto"]').val();
      const tipo = block.find('div[name="tipo"] .select-selected').attr('value');
      const sessao = block.find('div[name="sessão"] .select-selected').attr('value');
      const volume = block.find('div[name="volume"] .select-selected').attr('value');
      const issue = block.find('div[name="issue"] .select-selected').attr('value');


      // Construa a nova URL com os parâmetros selecionados
      let newURL = url + '&searchin=' + searchin;

      if (texto) {
        newURL += '&texto=' + encodeURIComponent(texto);
      }

      if (tipo) { // Certifique-se de verificar se o valor é válido antes de incluí-lo na URL
        newURL += '&tipo=' + encodeURIComponent(tipo);
      }

      if (sessao) { // Certifique-se de verificar se o valor é válido antes de incluí-lo na URL
        newURL += '&sessão=' + encodeURIComponent(sessao);
      }

      if (volume) { // Certifique-se de verificar se o valor é válido antes de incluí-lo na URL
        newURL += '&volume=' + encodeURIComponent(volume);
      }

      if (issue) { // Certifique-se de verificar se o valor é válido antes de incluí-lo na URL
        newURL += '&issue=' + encodeURIComponent(issue);
      }

      // Redirecione o navegador para a nova URL
      window.location.href = newURL;
    }

    form.on('submit', manageFormSubmit);

  }

  $(document).ready(function () {
    $('.custom-select').each(function () {
      var customSelect = $(this);
      var name = customSelect.find('div[name]').text(); // Obtém o texto da div 'name'
      var firstItem = customSelect.find('div[name] div[value]').first(); // Pega o primeiro item da lista

      var select = $('<select>').attr('name', name);
      var selectSelected = $('<div>').addClass('select-selected').text(firstItem.text()); // Defina o texto com base no primeiro item da lista
      var selectItems = $('<div>').addClass('select-items select-hide');

      selectItems.append(customSelect.find('div[name] div[value]'));
      customSelect.find('div[name]').empty().append(select).append(selectSelected).append(selectItems);

      selectSelected.on('click', function () {
        customSelect.toggleClass('is-open');

        if (customSelect.hasClass('is-open')) {
          customSelect.find('.select-items').removeClass('select-hide');
        } else {
          customSelect.find('.select-items').addClass('select-hide');
        }
      });

      selectItems.find('div[value]').on('click', function () {
        var selectedText = $(this).text();
        var selectedValue = $(this).attr('value');
        customSelect.find('.select-selected').text(selectedText).attr('value', selectedValue);
        customSelect.find('select').val(selectedValue);
        customSelect.removeClass('is-open');
        customSelect.find('.select-items').addClass('select-hide');
      });
    });
  });


});
